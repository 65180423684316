@import url('http://fonts.cdnfonts.com/css/libre-bodoni');

.App {
  text-align: center;
}

body{
  font-family: 'Libre Bodoni', sans-serif !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.soirree{ 
  margin-top: 20%; 
  margin-left: -60%;
font-size: xx-large;
  color: white;
}
.prijs{
  font-family: 'Arial', sans-serif !important;
}
.overmij{

    margin-top: 10%;
    margin-bottom: 15%; 
  margin-left: -60%;
font-size:200% !important;
  color: white;
}
.aanpak{

    margin-top: 10%;
    margin-bottom: 15%; 
  margin-left: -60%;  
}
.footer {
  background-color: #414141;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 50px;
  margin-top: 50px;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}
.text-camel{
  color: #C19A6B !important ;
}
.btn-camel{
  background-color: #C19A6B !important;
  border-color: #ffffff !important;
  font-size: 100% !important;
}
/* footer left*/

.footer .footer-left {
  width: 33%;
  padding-right: 15px;
}

.footer .about {
  line-height: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 25px;
}

.footer .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}


/* footer center*/

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none;
}


/* footer right*/

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

@media (max-width: 767px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer .footer-center i {
    margin-left: 0;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.container-iframe { position: relative; width: 100%; overflow: hidden; padding-top: 56.25%; /* 16:9 Aspect Ratio */ }
    .responsive-iframe { position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; border: none; }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 992px) {
 .adress{
  margin: auto;
  padding-top: 5%;
 }
 .aanpak{

  margin-top: 10%;
  margin-bottom: 35%; 
margin-left: -60%;
font-size:200%;
color: white;
}
.overmij{
 
margin-left: -50%; 
 
color: white;

}
 
}
@media screen and (max-width: 600px) {
  .png-size{
    height: 200px;
  }
  .adress{
   margin: auto;
   padding-top: 5%;
  }
  .soirree{ 
   margin-top: 80%; 
   margin-left: -40%;
 font-size:1cm !important;
   color: white;
 }
 .overmij{

  margin-top: 10%;
  margin-bottom: 15%; 
margin-left: -80%; 
color: white;
}
.aanpak{

  margin-top: 20%;
  margin-bottom: 35%; 
margin-left: -60%;
font-size:200%;
color: white;
}
 }
 @media screen and (max-width: 600px) {
  .overmij{

    margin-top: 30%;
    margin-bottom: 35%; 
  margin-left: -50%;
  font-size:200% !important;
  color: white;
  }
  .aanpak{

    margin-top: 30%;
    margin-bottom: 35%; 
  margin-left: -50%;
  font-size:200%;
  color: white;
  }

 }
